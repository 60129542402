import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import FormValidation from "../../commons/validation/FormValidation.js";
import Validator from "../../commons/validation/Validator.js";
import Loading from "../ui/Loading.js";

import moment from "moment";
import "moment/min/locales";
import Switch from "react-switch";
import Security from "../../commons/security/Security.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import AtributoDinamico from "../atributoDinamico/AtributoDinamico.js";
import * as Constants from "../atributoDinamico/constants.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import Select from "react-select";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

const ICONS = [
  "fa fa-car",
  "fa fa-truck",
  "fa fa-bus",
  "fa fa-motorcycle",
  "fa fa-user",
  "fa fa-users",
  "fa fa-wrench",
  "fa fa-cog",
  "fa fa-map-marker",
];

class TipoFormularioAbm extends Component {
  constructor(props) {
    super(props);
		this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				nombre: '',
				icono: '',
        descripcion: '',
				con_medidor: false,
				con_persona: false,
				con_movil: true,
				con_chofer: true,
				con_ticket: false,
        activo: true,
        atributos_dinamicos: [],
        perfiles: [],
        perfiles_responder: [],
        perfil_notificar_activo: false,
        movil_atributos: [],
      },
      estados: [
        {
          id: 1,
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.state_name_activo",
            defaultMessage: "Activo",
          }),
        },
        {
          id: 0,
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.state_name_inactivo",
            defaultMessage: "Inactivo",
          }),
        },
      ],
      movil_atributos: [
        {
          id: 'marca',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.marca",
            defaultMessage: "Marca",
          }),
        },
        {
          id: 'modelo',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.modelo",
            defaultMessage: "Modelo",
          }),
        },
        {
          id: 'chasis_nro',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.chasis_nro",
            defaultMessage: "Chasis",
          }),
        },
        {
          id: 'motor_nro',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.motor_nro",
            defaultMessage: "Motor",
          }),
        },
        {
          id: 'color',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.color",
            defaultMessage: "Color",
          }),
        },
        {
          id: 'centro_costos',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.centro_costos",
            defaultMessage: "Centro de Costos",
          }),
        },
        {
          id: 'plan_preventivo',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.plan_preventivo",
            defaultMessage: "Plan Preventivo",
          }),
        },
        {
          id: 'combustible',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.combustible",
            defaultMessage: "Combustible",
          }),
        },
        {
          id: 'proveedor_gps',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.proveedor_gps",
            defaultMessage: "Proveedor GPS",
          }),
        },
        {
          id: 'compania_origen',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.compania_origen",
            defaultMessage: "Compañia de Origen",
          }),
        },
        {
          id: 'titular',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.titular",
            defaultMessage: "Titular",
          }),
        },
        {
          id: 'telepeaje',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.telepeaje",
            defaultMessage: "Peaje",
          }),
        },
        {
          id: 'anio',
          nombre: this.props.intl.formatMessage({
            id: "tipoFormularioAbm.select_movil_atributos.anio",
            defaultMessage: "Año",
          }),
        },
      ],
      errors: [],
      perfilesSelect: [],
      loading: false,
      mostrarInactivos: ConfigBusiness.get('checklist.atributosDinamicos.mostrarInactivos') === 'true' ? true : false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
    this.handleAddAtributoDinamico = this.handleAddAtributoDinamico.bind(this);
    this.handleDeleteAtributoDinamico = this.handleDeleteAtributoDinamico.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleIconChange = this.handleIconChange.bind(this)
		this.handleConMedidorChange = this.handleConMedidorChange.bind(this)
		this.handlePerfilChange = this.handlePerfilChange.bind(this)
    this.handlePerfilResponderChange = this.handlePerfilResponderChange.bind(this);
    this.handlePerfilMovilAtributo = this.handlePerfilMovilAtributo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    this.formValidation = new FormValidation({
      component: this,
      validators: {
        "formData.nombre": (value) => Validator.notEmpty(value),
        "formData.icono": (value) => Validator.notEmpty(value),
      },
    });
  }

  onDragEnd(result) {
    if (!result.destination || result.destination.droppableId !== "1" ) return;
    this.setState({ loading: true });
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let atributos_dinamicos = formDataCopy.atributos_dinamicos;
    let atributoEnMovimiento = atributos_dinamicos[result.source.index];
    atributos_dinamicos.splice(result.source.index, 1);
    atributos_dinamicos.splice(result.destination.index, 0, atributoEnMovimiento);
    this.setState({ formData: formDataCopy }, () => this.setState({ loading: false }));
  }
  initForm() {
    this.setState({ loading: true });
    let component = this;
    let perfilesSelect = [];
    Promise.all([
      this.state.props.action !== "ADD"
        ? this.ajaxHandler.getJson("/tipoformulario/" + this.state.props.match.params.id)
        : null,
      this.ajaxHandler.getJson("/perfiles"),
    ])
      .then((data) => {
        let formDataNew = data[0];
        perfilesSelect = data[1];
        if (formDataNew) {
          formDataNew.atributos_dinamicos = formDataNew.atributos_dinamicos.map((atributo) => {
            atributo.value = atributo.valorDefault;
            return atributo;
          });
          if(formDataNew.movil_atributos_json){
            let movil_atributos = [];
            let movil_atributos_array = JSON.parse(formDataNew.movil_atributos_json);
            this.state.movil_atributos.forEach(function(element) {
              if(movil_atributos_array.includes(element.id))
                movil_atributos.push(element);
            })
            formDataNew.movil_atributos = movil_atributos;
          }

          component.setState({
            formData: formDataNew,
          });
        } else this.handleAddAtributoDinamico();
      })
      .catch(function (error) {
        console.log(error);
        component.exit();
      })
      .finally(() => {
        this.setState({
          loading: false,
          perfilesSelect: perfilesSelect,
        });
      });
  }
  componentWillMount() {
    if ((Security.hasPermission('CHECKLIST_CREAR_TIPO_FORMULARIOS') && this.state.props.action === 'ADD') ||
    (Security.hasPermission('CHECKLIST_EDITAR_TIPO_FORMULARIOS') && this.state.props.action === 'EDIT') ||
    (Security.hasPermission('CHECKLIST_VISUALIZAR_TIPO_FORMULARIOS') && this.state.props.action === 'VIEW')) {
        this.ajaxHandler.subscribe(this);
        this.initForm();
    } else {
        this.setState({
            redirectTo: '/error'
        });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleCheckboxChange(name, activo) {
    this.state.formData[name] = activo;
    if (name === "con_persona" && activo) this.state.formData["con_chofer"] = !activo;
    this.setState({
      formData: this.state.formData,
    });
  }

	handleConMedidorChange(activo) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

		formDataCopy["con_medidor"] = activo;
		let atributoMedidorIndex = formDataCopy.atributos_dinamicos.findIndex(atributo => atributo.tipoValor == Constants.ID_MEDIDOR)
		if (atributoMedidorIndex == -1) {
			if (activo) {
				let templateAtributoCopy = JSON.parse(JSON.stringify(Constants.TEMPLATE_ATRIBUTO_MEDIDOR));
				formDataCopy.atributos_dinamicos.push(templateAtributoCopy);
			}
		}

		else {
			if (formDataCopy.atributos_dinamicos[atributoMedidorIndex].id )
					formDataCopy.atributos_dinamicos[atributoMedidorIndex].activo = activo
			else
					formDataCopy.atributos_dinamicos.splice(atributoMedidorIndex, 1)
		}
		this.setState({
			formData: formDataCopy
		});
	}


	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.state.formData[name] = value;
		this.setState({
			formData: this.state.formData
		});
  }

  handleInputFormChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.state.formData[name] = value;
    this.setState({
      formData: this.state.formData,
    });
  }

  handleAddAtributoDinamico() {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let templateAtributoCopy = JSON.parse(JSON.stringify(Constants.TEMPLATE_ATRIBUTO));
    formDataCopy.atributos_dinamicos.push(templateAtributoCopy);
    this.setState({
      formData: formDataCopy,
    });
  }

  handleDeleteAtributoDinamico(index) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.atributos_dinamicos.splice(index, 1);
    this.setState({
      formData: formDataCopy,
    });
  }

  handleChangeAtributoDinamico(index, key, value) {
    let atributoDinamico = this.state.formData.atributos_dinamicos[index];
    atributoDinamico[key] = value;
    if (key == "value") atributoDinamico.valorDefault = value;
    this.setState({
      formData: this.state.formData,
    });
  }

  handleIconChange(e, icono) {
    e.preventDefault();
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["icono"] = icono;
    this.setState({
      formData: formDataCopy,
    });
  }

  handlePerfilChange(perfiles) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["perfiles"] = perfiles;
    this.setState({
      formData: formDataCopy,
    });
  }

  handlePerfilResponderChange(perfiles) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["perfiles_responder"] = perfiles;
    this.setState({
      formData: formDataCopy,
    });
  }
  
  handlePerfilMovilAtributo(atributos){
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["movil_atributos"] = atributos;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleSubmit(event) {
    this.setState({ loading: true });
    let component = this;
    this.state.formData.perfiles_ids = this.state.formData.perfiles.map((perfil) => perfil.id);
    this.state.formData.perfiles_responder_ids = this.state.formData.perfiles_responder.map((perfil) => perfil.id);
    this.state.formData.movil_atributos_json = this.state.formData.movil_atributos ? JSON.stringify(this.state.formData.movil_atributos.map((atributo) => atributo.id)) : null;
    this.state.formData.atributos_dinamicos.map((atributo, index) => {
      atributo.order = index;
      return atributo;
    });
    this.ajaxHandler
      .fetch("/tipoformulario" + (this.props.action === "ADD" ? "" : "/" + this.state.formData.id), {
        method: this.props.action === "ADD" ? "POST" : "PUT",
        body: JSON.stringify({
          ...this.state.formData,
        }),
      })
      .then((response) => {
        if (response.status !== 400) {
          component.exit();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        component.ajaxHandler.handleError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    event.preventDefault();
  }

  handleCancel(event) {
    this.exit();
  }

  exit() {
    this.setState({
      redirectTo: "/tipoformulario",
    });
  }

  atributoDinamicoServicosRequeridosFaltantes() {
    return (this.state.formData.atributos_dinamicos.filter(
      function(attribute){
        if(attribute.genera_ticket_gestoria)
          return !attribute.servicio_gestoria_id;
        if(attribute.genera_ticket)
          return attribute.tareas.length === 0
      }
    )).length
  }

  render() {
    let state = this.state;
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings["errors.default"])} />
                </li>
              ))}
            </div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i>
                        <FormattedMessage
                          id="tipoFormularioAbm.render.general_data.header_datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                        <div className="float-right" style={{ fontSize: "14px" }}>
                          <FormattedMessage
                            id="tipoFormularioAbm.render.general_data.campos_requeridos"
                            defaultMessage="* campos requeridos"
                          />
                        </div>
                      </h4>

                      <div className="row form-group">
                        {/* Icono */}
                        <div className="col-md-6">
                          <div className="row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="icono">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_tipo"
                                defaultMessage="Tipo *:"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="row" style={{ height: "20px" }}>
                            <div className="offset-md-2 col-md-10" style={{ bottom: "40px" }}>
                              {this.props.action === "VIEW" && <i className={formData.icono + " fa-2x"} />}
                              {this.props.action !== "VIEW" &&
                                ICONS.map((icono, index) => (
                                  <button
                                    disabled={this.props.action === "VIEW"}
                                    className={
                                      formData.icono === icono
                                        ? icono + " btn btn-primary fa-2x bt-icon-select"
                                        : icono + " btn btn-default fa-2x bt-icon-select"
                                    }
                                    key={icono}
                                    onClick={(e) => this.handleIconChange(e, icono)}
                                  ></button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Nombre */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_nombre"
                                defaultMessage="Nombre *:"
                              />
                            </label>
                            <div className="col-md-9">
                              <input
                                disabled={this.props.action === "VIEW"}
                                type="text"
                                className="form-control"
                                id="nombre"
                                name="nombre"
                                placeholder={this.props.intl.formatMessage({
                                  id: "tipoFormularioAbm.render.general_data.placeholder_nombre",
                                  defaultMessage: "Modelo",
                                })}
                                value={formData.nombre}
                                onChange={this.handleInputFormChange}
                              />
                              <div
                                className="help-block text-danger field-message"
                                hidden={
                                  validationState.formData.nombre.pristine || validationState.formData.nombre.valid
                                }
                              >
                                {validationState.formData.nombre.message}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Descripción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_descripcion"
                                defaultMessage="Descripción:"
                              />
                            </label>
                            <div className="col-md-9">
                              <textarea
                                disabled={this.props.action === "VIEW"}
                                className="form-control"
                                id="descripcion"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={this.handleInputFormChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_movil"
                                defaultMessage="Móvil:"
                              />
                            </label>
                            <div className="col-md-3 mt-auto">
                              <Switch
                                onChange={value => {
																		this.handleCheckboxChange("con_movil", value)
																		this.handleCheckboxChange("con_persona", !value)
																		if (!value) {
																			this.handleConMedidorChange(false)
																		}else{
																			this.handleCheckboxChange("con_ticket", false)
																		}
																	}
																}
                                checked={formData.con_movil}
                                options={this.state.estados}
                                valueKey="id"
                                labelKey="nombre"
                                disabled={this.state.props.action === "VIEW" ? true : false}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>

                            {/* Persona */}
														<label className="col-md-3 label-control col-form-label" htmlFor="activo">
															<FormattedMessage id="tipoFormularioAbm.render.general_data.label_persona" defaultMessage="Persona:"/>
														</label>
														<div className="col-md-3 mt-auto">
															<Switch
																onChange={value => {
																		this.handleCheckboxChange("con_persona", value)
																		this.handleCheckboxChange("con_movil", !value)
																		this.handleCheckboxChange("con_chofer", !value)
																		if (value) {
																			this.handleCheckboxChange("con_ticket", false)
																			this.handleConMedidorChange(false)
																			this.handleCheckboxChange('con_chofer', false)
																		}
																	}
																}
																checked={formData.con_persona}
																options={this.state.estados}
																valueKey='id'
																labelKey='nombre'
																disabled={this.state.props.action === 'VIEW' ? true: false }
																offColor="#FF4961"
																onColor="#28D094"
															/>
														</div>
                          </div>
                        </div>
												<div className="col-md-6">
													<div className="form-group row">
														{/* Ticket */}
														<label className="col-md-3 label-control col-form-label" htmlFor="activo">
															<FormattedMessage id="Ticket" defaultMessage="Ticket"/>:
														</label>
														<div className="col-md-3 mt-auto">
															<Switch
																onChange={value => {
																		this.handleCheckboxChange("con_ticket", value ? true: false)
																		this.handleCheckboxChange("con_persona", value ? false: false)
																		this.handleCheckboxChange("con_movil", value ? false: true)
																		this.handleCheckboxChange("con_chofer", !value)
																		this.handleConMedidorChange(false)
																	}
																}
																checked={formData.con_ticket}
																options={this.state.estados}
																valueKey='id'
																labelKey='nombre'
																disabled={this.state.props.action === 'VIEW' ? true: false }
																offColor="#FF4961"
																onColor="#28D094"
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
                        <div className="col-md-6">
													<div className="form-group row">
														{formData.con_movil &&
															<>
																<label className="col-md-3 label-control col-form-label" htmlFor="activo">
		                              <FormattedMessage id="tipoFormularioAbm.render.general_data.label_actualiza_medidor" defaultMessage="Actualiza Medidor:"/>
		                            </label>
		                            <div className="col-md-3 mt-auto">
		                              <Switch
		                                onChange={value => this.handleConMedidorChange(value)}
		                                checked={formData.con_medidor}
		                                options={this.state.estados}
		                                valueKey='id'
		                                labelKey='nombre'
		                                disabled={this.state.props.action === 'VIEW'}
		                                offColor="#FF4961"
		                                onColor="#28D094"
		                              />
		                            </div>
																<label className="col-md-3 label-control col-form-label" htmlFor="activo">
		                              Actualizar Chofer:
		                            </label>
		                            <div className="col-md-3 mt-auto">
		                              <Switch
		                                onChange={value => this.handleCheckboxChange('con_chofer', value)}
		                                checked={formData.con_chofer}
		                                options={this.state.estados}
		                                valueKey='id'
		                                labelKey='nombre'
		                                disabled={this.state.props.action === 'VIEW'}
		                                offColor="#FF4961"
		                                onColor="#28D094"
		                              />
		                            </div>
															</>
														}
													</div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Perfil */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
                              Perfil a Notificar:
                            </label>
                            <div className="col-md-9">
                              <Select
                                placeholder="Seleccione"
                                options={this.state.perfilesSelect}
                                valueKey="id"
                                labelKey="nombre"
                                multi
                                value={formData.perfiles}
                                onChange={(e) => this.handlePerfilChange(e)}
                                disabled={this.state.props.action === "VIEW"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/*Perfil Notificar Activar*/}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="perfil_notificar_activo">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_perfil_responder_activo"
                                defaultMessage="Perfil a Responder:"
                              />
                            </label>
                            <div className="col-md-9 mt-auto">
                              <Switch
                                onChange={(value) => this.handleCheckboxChange("perfil_notificar_activo", value)}
                                checked={formData.perfil_notificar_activo}
                                options={this.state.estados}
                                id="perfil_notificar_activo"
                                name="perfil_notificar_activo"
                                valueKey="id"
                                labelKey="nombre"
                                disabled={this.state.props.action === "VIEW" ? true : false}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {formData.perfil_notificar_activo ?
                      <div className="row">
                        {/* Perfil Notificar*/}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="perfiles_responder">
                              <FormattedMessage id="tipoFormularioAbm.render.general_data.label_perfiles_responder" defaultMessage="Perfil a Responder:"/>
                            </label>
                            <div className="col-md-9">
                              <Select
                                placeholder="Seleccione"
                                options={this.state.perfilesSelect}
                                valueKey="id"
                                labelKey="nombre"
                                multi
                                value={formData.perfiles_responder}
                                onChange={(e) => this.handlePerfilResponderChange(e)}
                                disabled={this.state.props.action === "VIEW"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''}
                      {formData.con_movil ?
                      <div className="row">
                        {/* Atributos de Movil*/}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="perfiles_responder">
                              <FormattedMessage id="tipoFormularioAbm.render.general_data.label_atributo_de_vehiculo" defaultMessage="Atributo de Vehículo:"/>
                            </label>
                            <div className="col-md-9">
                              <Select
                                placeholder="Seleccione"
                                options={this.state.movil_atributos}
                                valueKey="id"
                                labelKey="nombre"
                                multi
                                value={formData.movil_atributos}
                                onChange={(e) => this.handlePerfilMovilAtributo(e)}
                                disabled={this.state.props.action === "VIEW"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''}
                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage
                                id="tipoFormularioAbm.render.general_data.label_activo"
                                defaultMessage="Activo:"
                              />
                            </label>
                            <div className="col-md-9 mt-auto">
                              <Switch
                                onChange={(value) => this.handleCheckboxChange("activo", value)}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey="id"
                                labelKey="nombre"
                                disabled={this.state.props.action === "VIEW" ? true : false}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.loading && (
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={"1"}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={snapshot.isDraggingOver ? { background: "lightblue" } : {}}
                        >
                          {this.state.formData.atributos_dinamicos.map(
                            (atributoDinamico, index) =>
                              atributoDinamico &&
                              (atributoDinamico.activo || this.state.mostrarInactivos) && (
                                <AtributoDinamico
                                  mostrarInactivos={this.state.mostrarInactivos}
                                  handleDelete={this.handleDeleteAtributoDinamico}
                                  index={index}
                                  key={index}
                                  previsualizacion={true}
                                  modulo={"checklist"}
                                  disabled={this.state.props.action === "VIEW"}
                                  draggable={this.state.props.action !== "VIEW"}
                                  handleChange={this.handleChangeAtributoDinamico}
                                  formulario={atributoDinamico}
                                  tipoPersona={this.state.formData.con_persona}
																	tipoTicket={this.state.formData.con_ticket}
                                />
                              )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== "VIEW" && (
                          <>
                            <button
                              type="button"
                              className="btn btn-success pull-left mr-1"
                              onClick={this.handleAddAtributoDinamico}
                            >
                              <i className="fa fa-plus"></i>
                              <FormattedMessage
                                id="tipoFormularioAbm.render.add_attribute_button.agregar_atributo_dinamico"
                                defaultMessage=" Agregar Atributo Dinámico"
                              />
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary mr-1"
                              disabled={!validationState.form.valid || this.atributoDinamicoServicosRequeridosFaltantes()}
                            >
                              <i className="fa fa-check-circle"></i>
                              <FormattedMessage
                                id="tipoFormularioAbm.render.finish_button.guardar"
                                defaultMessage=" Guardar"
                              />
                            </button>
                          </>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i>{" "}
                          {this.props.action === "VIEW"
                            ? this.props.intl.formatMessage({
                                id: "tipoFormularioAbm.render.finish_button.volver",
                                defaultMessage: "Volver",
                              })
                            : this.props.intl.formatMessage({
                                id: "tipoFormularioAbm.render.finish_button.cancelar",
                                defaultMessage: "Cancelar",
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(TipoFormularioAbm);
