import React, { useState, useEffect, useLayoutEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Modal,
  Backdrop,
  Grow,
} from '@material-ui/core';
import Switch from "react-switch";
import SelectWithIcons from "../selectWithIcons/SelectWithIcons.js";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Portal } from 'react-overlays';
import $ from 'jquery'

const CalendarContainer = ({ children }) => {
  return (
    <Portal >
      {children}
    </Portal>
  )
}

const FormulariosGridExport = (props) => {
  const intl = useIntl();
  const [FechaDesde, setFechaDesde] = useState(moment().startOf('year'))
  const [FechaHasta, setFechaHasta] = useState(moment())
  const [tiposFormulario, setTiposFormulario] = useState([])
  const [tipoFormulario, setTipoFormulario] = useState(null)
  const [dataToExport, setDataToExport] = useState(null)
  const [attrDinamicos, setAttrDinamicos] = useState(null)
  const [loading, setLoading] = useState(false);
  const [conTicket, setConTicket] = useState(false);
  const filterQuery = props.filterQuery;

  useEffect(() => {
    Utils.getData('/tipoformulario')
      .then((data) => {
        let options = data.map(tipo => {
          return { label: tipo.nombre, id: tipo.id, icono: tipo.icono }
        })
        setTiposFormulario(options)

      }).catch((err) => {
        console.log(err);
      })

    if (!props.modalExport) {
      setFechaDesde(moment().startOf('year'))
      setFechaHasta(moment())
      setTipoFormulario(null)
      setDataToExport(null)
      setAttrDinamicos(null)
      setLoading(false)
    }
  }, [props.modalExport])

  useEffect(() => {
    setDataToExport(null);
    tipoFormulario && Utils.getData('/tipoformulario/' + tipoFormulario)
      .then(data => {
        let toExport = {}
        let attrDinamicos = data.atributos_dinamicos.map((attr) => {
          while (attr.activo) {
            let attrName = attr.nombre;

            toExport[attrName] = true
            return { id: attr.id, nombre: attr.nombre }
          }
        });
        toExport['region'] = true
        toExport['subRegion'] = true
        if(data.con_ticket){
          toExport['ticket'] = true
          toExport['dominio'] = true
          toExport['fechaCompletado'] = true
          toExport['usuarioValido'] = true
          toExport['fechaValidado'] = true
          toExport['usuarioCompletado'] = true
        }
        setAttrDinamicos(attrDinamicos);
        setDataToExport(toExport);
        setConTicket(data.con_ticket ? true : false)

      }).catch((err) => {
        console.log(err);
      })

  }, [tipoFormulario])

  const handleDataExportChange = (attr) => {
    let dataToExportUpdate = { ...dataToExport }
    dataToExportUpdate[attr.nombre] = !dataToExportUpdate[attr.nombre];
    setDataToExport(dataToExportUpdate)
  }

  const handleExport = () => {
    setLoading(true)

    let body = JSON.stringify({
      fechaDesde: FechaDesde.format('YYYY-MM-DD'),
      fechaHasta: FechaHasta.format('YYYY-MM-DD'),
      tipoFormulario: tipoFormulario,
      colsAExportar: dataToExport,
      ...filterQuery
    })

    Utils.sendDataBlob('/formulario/exportar-excel', 'POST', body)
      .then(data => {
        let file = new File([data], 'VecFleet-checklist.xlsx')
        let fileUrl = URL.createObjectURL(file)
        $("<a />", {
          href: fileUrl,
          download: "VecFleet-checklist.xlsx"
        }).appendTo("body").get(0).click();
      }
      ).catch((err) => {
        console.log(err)
      }).finally(() => {
        props.setModalExport(false);
        setLoading(false)
      })

  }

  return (
    <Modal open={props.modalExport}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Grow in={props.modalExport}>
        <div className="" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel3">
                  <i className="ft-download align-middle icon-modal-title"></i>
                  <FormattedMessage id="formularioGrid.render.export_modal.header_exportar_checklist" defaultMessage=" Exportar Checklist" />
                </h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setModalExport(false)}>
                  <span aria-hidden="true">&times; </span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <div className="row">
                  <div className="col-md-12">
                    <form className="form form-horizontal">
                      <div className="form-body">
                        <div className="card pull-up">
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="form-section">
                                <i className="la la-filter"></i>
                                <FormattedMessage id="formularioGrid.render.export_modal.filter.header_filtros" defaultMessage=" Filtros" />
                                <div className="float-right" style={{ fontSize: '14px' }}>*
                                  <FormattedMessage id="formularioGrid.render.export_modal.filter.campos_requeridos" defaultMessage="campos requeridos" />
                                </div>
                              </h4>
                              <div className="form-group row col-md-12">
                                <label className="col-md-2 label-control col-form-label" htmlFor="fechaDesde">
                                  <FormattedMessage id="formularioGrid.render.export_modal.filter.label_desde" defaultMessage="Desde " />*:
                                </label>
                                <div className="col-md-2">
                                  <DatePicker
                                    popperContainer={CalendarContainer}
                                    id="FechaDesde"
                                    name="FechaDesde"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={intl.formatMessage({ id: 'formularioGrid.render.export_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
                                    maxDate={FechaHasta}
                                    openToDate={FechaDesde}
                                    selected={FechaDesde}
                                    onChange={(event, value) => { setFechaDesde(moment(event)) }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selectsStart
                                    startDate={FechaDesde}
                                    endDate={FechaHasta}
                                    disabled={loading}
                                  />
                                </div>
                                <label className="col-md-1 label-control col-form-label" htmlFor="fechaHasta">
                                  <FormattedMessage id="formularioGrid.render.export_modal.filter.hasta" defaultMessage="Hasta " />*:
                                </label>
                                <div className="col-md-2">
                                  <DatePicker
                                    popperContainer={CalendarContainer}
                                    id="FechaHasta"
                                    name="FechaHasta"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={intl.formatMessage({ id: 'formularioGrid.render.export_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
                                    minDate={FechaDesde}
                                    maxDate={moment()}
                                    openToDate={FechaHasta}
                                    selected={FechaHasta}
                                    onChange={(event, value) => { setFechaHasta(moment(event)) }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selectsEnd
                                    startDate={FechaDesde}
                                    endDate={FechaHasta}
                                    disabled={loading}
                                  />
                                </div>
                                <label className="col-md-2 label-control col-form-label" htmlFor="tipoFormulario">
                                  <FormattedMessage id="formularioGrid.render.export_modal.filter.tipo_checklist" defaultMessage="Tipo de Checklist " />*:
                                </label>
                                <div className="col-md-3">
                                  <SelectWithIcons
                                    placeholder="Seleccione"
                                    valueKey="id"
                                    labelKey="label"
                                    clearable={false}
                                    disabled={loading}
                                    onChange={(e) => setTipoFormulario(e.id)}
                                    value={tipoFormulario}
                                    options={tiposFormulario}
                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card pull-up">
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="form-section">
                                <i className="la la-columns"></i>
                                <FormattedMessage id="formularioGrid.render.export_modal.columns_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar" />
                              </h4>
                              {dataToExport == null ? (
                                <label>
                                  <FormattedMessage id="formularioGrid.render.export_modal_seleccione_tipo_de_ckecklist" defaultMessage=" Seleccione Tipo de Checklist para continuar" />
                                </label>
                              ) : (
                                <>
                                <div className="row m-1">
                                  {attrDinamicos !== null ?
                                    attrDinamicos.map((attr) => {
                                      return attr && (
                                        <div className="col-md-3" key={attr.id}>
                                          <label className="col-md-6 label-control col-form-label" htmlFor={attr.nombre}>
                                            {attr.nombre}
                                          </label>
                                          <Switch
                                            className="col-md-6 align-middle"
                                            id={attr.nombre}
                                            name={attr.nombre}
                                            value={attr.id}
                                            onChange={(event) => handleDataExportChange(attr)}
                                            checked={dataToExport[attr.nombre]}
                                            offColor="#FF4961"
                                            onColor="#28D094"
                                            disabled={loading}
                                          />
                                        </div>
                                      )
                                    }) : ''}
                                 {/* Region */}
                                 <div className="col-md-3">
                                   <label className="col-md-6 label-control col-form-label" htmlFor="region">
                                     <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_region" defaultMessage="Región:"/>
                                   </label>
                                   <Switch
                                     onChange={(event) => handleDataExportChange({nombre: 'region'})}
                                     checked = {dataToExport['region']}
                                     value={dataToExport['region']}
                                     id = "region"
                                     name = "region"
                                     offColor = "#FF4961"
                                     onColor = "#28D094"
                                     disabled = {loading}
                                     className = "col-md-6 align-middle"
                                   />
                                 </div>
                                 {/* SubRegion */}
                                 <div className="col-md-3">
                                   <label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
                                     <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_subRegion" defaultMessage="Sub-Región:"/>
                                   </label>
                                   <Switch
                                     onChange={(event) => handleDataExportChange({nombre: 'subRegion'})}
                                     checked = {dataToExport['subRegion']}
                                     value={dataToExport['subRegion']}
                                     id = "subRegion"
                                     name = "subRegion"
                                     offColor = "#FF4961"
                                     onColor = "#28D094"
                                     disabled = {loading}
                                     className =  "col-md-6 align-middle"
                                   />
                                 </div>
                                 { conTicket ? 
                                  (<><div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="ticket">
                                      <FormattedMessage id="Nro_de_ticket" defaultMessage="Nro. de ticket"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'ticket'})}
                                      checked = {dataToExport['ticket']}
                                      value={dataToExport['ticket']}
                                      id = "ticket"
                                      name = "ticket"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="dominio">
                                      <FormattedMessage id="Dominio_del_vehiculo" defaultMessage="Dominio del vehículo"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'dominio'})}
                                      checked = {dataToExport['dominio']}
                                      value={dataToExport['dominio']}
                                      id = "dominio"
                                      name = "dominio"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="usuarioCompletado">
                                      <FormattedMessage id="Usuario_que_completo_el_checklist" defaultMessage="Usuario que completó el checklist"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'usuarioCompletado'})}
                                      checked = {dataToExport['usuarioCompletado']}
                                      value={dataToExport['usuarioCompletado']}
                                      id = "usuarioCompletado"
                                      name = "usuarioCompletado"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="fechaCompletado">
                                      <FormattedMessage id="Fecha_que_se_completo_el_checklist" defaultMessage="Fecha que se completó el checklist"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'fechaCompletado'})}
                                      checked = {dataToExport['fechaCompletado']}
                                      value={dataToExport['fechaCompletado']}
                                      id = "fechaCompletado"
                                      name = "fechaCompletado"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="usuarioValido">
                                      <FormattedMessage id="Usuario_que_valido_el_checklist" defaultMessage="Usuario que validó el checklist"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'usuarioValido'})}
                                      checked = {dataToExport['usuarioValido']}
                                      value={dataToExport['usuarioValido']}
                                      id = "usuarioValido"
                                      name = "usuarioValido"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="col-md-6 label-control col-form-label" htmlFor="fechaValidado">
                                      <FormattedMessage id="Fecha_que_se_valido_el_checklist" defaultMessage="Fecha que se validó el checklist"/>:
                                    </label>
                                    <Switch
                                      onChange={(event) => handleDataExportChange({nombre: 'fechaValidado'})}
                                      checked = {dataToExport['fechaValidado']}
                                      value={dataToExport['fechaValidado']}
                                      id = "fechaValidado"
                                      name = "fechaValidado"
                                      offColor = "#FF4961"
                                      onColor = "#28D094"
                                      disabled = {loading}
                                      className =  "col-md-6 align-middle"
                                    />
                                  </div></>)
                                : ''}
                               </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer modal-grey">
                {loading ?
                  <button type="button" className="btn btn-default btn-fleet">
                    <i className={'la la-spinner spinner'}></i>
                    <FormattedMessage id="formularioGrid.render.export_modal.finish_button.exportar_a_excel_cargando" defaultMessage=" Preparando" />
                  </button>
                  :
                  <button type="button" className="btn btn-default btn-fleet" onClick={handleExport} disabled={!tipoFormulario}>
                    <i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i>
                    <FormattedMessage id="formularioGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel" />
                  </button>
                }
                <button
                  type="button" className="btn btn-danger" disabled={loading} onClick={() => props.setModalExport(false)}>
                  <i className="fa fa-times-circle"></i>
                  <FormattedMessage id="formularioGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Grow>
    </Modal>
  )
}

export default FormulariosGridExport
