import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'

import moment from 'moment'
import 'moment/min/locales'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AtributoDinamicoInstancia from '../atributoDinamico/AtributoDinamicoInstancia.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { atributosDinamicosRequeridosFaltantes, castValoresDinamicos } from '../atributoDinamico/helpers.js'
import * as Constants from '../atributoDinamico/constants.js'
import swal from 'sweetalert'
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR }  from '../unidadMedidor/constants.js'
import FormularioTicketTareas from './FormularioTicketTareas.js';
import $ from 'jquery'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';

import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class FormularioAbm extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");

    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        valores_dinamicos: [],
        medidor: 0,
        activo: 1,
        tipo_formulario: null,
        movil: null,
        chofer: null,
        persona: null,
        ticket: null,
        tareas: [],
        tareasEnResolucion: false,
      },
      valores_dinamicos: [],
      latitudForm: null,
      longitudForm: null,
      descripcion: "",
      estados: [
        {
          id: 1,
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.form_status.name_activo",
            defaultMessage: "Activo",
          }),
        },
        {
          id: 0,
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.form_status.name_inactivo",
            defaultMessage: "Inactivo",
          }),
        },
      ],
      movil_atributos_mostrar: [],
      movil: null,
      movil_atributos: [
        {
          id: 'marca',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.marca",
            defaultMessage: "Marca",
          }),
        },
        {
          id: 'modelo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.modelo",
            defaultMessage: "Modelo",
          }),
        },
        {
          id: 'chasis_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.chasis_nro",
            defaultMessage: "Chasis",
          }),
        },
        {
          id: 'motor_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.motor_nro",
            defaultMessage: "Motor",
          }),
        },
        {
          id: 'color',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.color",
            defaultMessage: "Color",
          }),
        },
        {
          id: 'centro_costos',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.centro_costos",
            defaultMessage: "Centro de Costos",
          }),
        },
        {
          id: 'plan_preventivo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.plan_preventivo",
            defaultMessage: "Plan Preventivo",
          }),
        },
        {
          id: 'combustible',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.combustible",
            defaultMessage: "Combustible",
          }),
        },
        {
          id: 'proveedor_gps',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.proveedor_gps",
            defaultMessage: "Proveedor GPS",
          }),
        },
        {
          id: 'compania_origen',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.compania_origen",
            defaultMessage: "Compañia de Origen",
          }),
        },
        {
          id: 'titular',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.titular",
            defaultMessage: "Titular",
          }),
        },
        {
          id: 'telepeaje',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.telepeaje",
            defaultMessage: "Peaje",
          }),
        },
        {
          id: 'anio',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.anio",
            defaultMessage: "Año",
          }),
        },
      ],
      errors: [],
      loading: false,
      isChoferValid: true,
      tareas: [],
      servicios: [],
      entidad: null,
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleTipoFormularioChange = this.handleTipoFormularioChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.getBackendMoviles = this.getBackendMoviles.bind(this);
    this.handlePersonaChange = this.handlePersonaChange.bind(this);
    this.handleChoferChange = this.handleChoferChange.bind(this);
    this.getBackendPersonas = this.getBackendPersonas.bind(this);
    this.getBackendTipoForms = this.getBackendTipoForms.bind(this);
    this.getBackendPersonasPorBase = this.getBackendPersonasPorBase.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.ticketTareasModal = this.ticketTareasModal.bind(this);
    this.getTareas = this.getTareas.bind(this);
    this.getBackendTickets = this.getBackendTickets.bind(this);
    this.handleTicketChange = this.handleTicketChange.bind(this);

    this.formValidation = new FormValidation({
      component: this,
      validators: {
        "formData.tipo_formulario": (value) => Validator.notEmpty(value),
      },
    });
  }
  initForm() {
    this.setState({ loading: true });
    let component = this;

    Promise.all([
      this.state.props.action !== "ADD"
        ? this.ajaxHandler.getJson("/formulario/" + this.state.props.match.params.id)
        : null
    ])
      .then((data) => {
        let formData = data[0];
        let valores_dinamicos = [];
        let movil_atributos_mostrar = [];
        if (formData) {
          let key_validator = '';
          if (formData.tipo_formulario.con_movi) {
            key_validator = 'formData.movil';
          }else if(formData.tipo_formulario.con_persona){
            key_validator = 'formData.movil';
          }else{
            key_validator = 'formData.ticket';
          }

          let validator = {};
          validator["formData.tipo_formulario"] = (value) => Validator.notEmpty(value);
          validator[key_validator] = (value) => Validator.notEmpty(value);
          if(key_validator === 'formData.movil' && formData.tipo_formulario.con_chofer) {
            validator["formData.chofer"] = (value) => Validator.notEmpty(value);
          }
          this.formValidation = new FormValidation({
            component: this,
            validators: validator,
          });
          if(formData.tipo_formulario.movil_atributos_json && formData.tipo_formulario.con_movil){
            this.setState({ loading: true });
            Promise.all([
              this.ajaxHandler.getJson("/moviles/" + formData.movil.id)
            ])
            .then((data) => {
              component.setState({
                movil: data[0],
              });
            })
            .catch(function (error) {
              console.log(error);
              component.exit();
            })
            .finally(() => {
              this.setState({ loading: false });
            });

            let movil_atributos_array = JSON.parse(formData.tipo_formulario.movil_atributos_json);
            this.state.movil_atributos.forEach(function(element) {
              if(movil_atributos_array.includes(element.id))
                movil_atributos_mostrar.push(element);
            });
          }
          valores_dinamicos = castValoresDinamicos(
            formData.tipo_formulario.atributos_dinamicos,
            formData.valores_dinamicos
          );
          component.setState({
            formData: formData,
            valores_dinamicos: valores_dinamicos,
            movil_atributos_mostrar: movil_atributos_mostrar
          });
        }
        // Security.hasPermission('USUARIO_POSEER_MOVIL')
        let userChofer = JSON.parse(localStorage.getItem("persona"));
        if (Security.hasPermission("USUARIO_POSEER_MOVIL")) {
          let formDataCopy = this.state.formData;
          formDataCopy.chofer = userChofer;
          this.setState({ formData: formDataCopy });
          this.setState({ isChoferValid: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        component.exit();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  componentWillMount() {

    this.ajaxHandler.subscribe(this);
    if ((Security.hasPermission('CHECKLIST_CREAR_HISTORICO') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('CHECKLIST_EDITAR_HISTORICO') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('CHECKLIST_VISUALIZAR_HISTORICO') && this.state.props.action === 'VIEW')) {
            this.initForm();
            this.getLocation();
		} else {
			this.setState({
				redirectTo: '/error'
			});
        }
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["activo"] = activo;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleMovilChange(movil) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["movil"] = movil;
    this.setState({
      formData: formDataCopy,
      entidad: movil,
      movil: movil
    });
  }

  handlePersonaChange(persona) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["persona"] = persona;
    this.setState({
      formData: formDataCopy,
      entidad: persona
    });
  }

  handleTicketChange(ticket) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["ticket"] = ticket;
    this.setState({
      formData: formDataCopy,
      entidad: ticket
    });
  }

  handleChoferChange(chofer) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["chofer"] = chofer;
    this.setState({
      formData: formDataCopy,
      chofer: chofer,
    });
  }

  handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleChangeAtributoDinamico(index, key, value) {
    this.state.valores_dinamicos[index][key] = value;
    this.setState({
      valores_dinamicos: this.state.valores_dinamicos
    });
	}

	handleTipoFormularioChange(tipo_formulario) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let valores_dinamicos = [];
		formDataCopy.movil = null;
		formDataCopy.persona = null;
    let movil_atributos_mostrar = [];
		formDataCopy.tipo_formulario = tipo_formulario;
		if (tipo_formulario){
			valores_dinamicos = castValoresDinamicos(tipo_formulario.atributos_dinamicos, this.state.formData.valores_dinamicos);
      if(tipo_formulario.movil_atributos_json && tipo_formulario.con_movil){
        let movil_atributos_array = JSON.parse(tipo_formulario.movil_atributos_json);
        this.state.movil_atributos.forEach(function(element) {
          if(movil_atributos_array.includes(element.id))
            movil_atributos_mostrar.push(element);
        });
      }
    }
		let key_validator = '';
    if (tipo_formulario.con_movil) {
      key_validator = 'formData.movil';
    }else if(tipo_formulario.con_persona){
      key_validator = 'formData.movil';
    }else{
      key_validator = 'formData.ticket';
    }
		let validator = {}
		validator['formData.tipo_formulario'] = (value) => Validator.notEmpty(value)
    if(key_validator === 'formData.movil' && tipo_formulario.con_chofer){
      validator["formData.chofer"] = (value) => Validator.notEmpty(value);
    }
		validator[key_validator] = (value) => Validator.notEmpty(value)
		this.formValidation = new FormValidation({
			component: this,
			validators: validator
    });
		this.setState({
			formData: formDataCopy,
			valores_dinamicos: valores_dinamicos,
      entidad: null,
      movil_atributos_mostrar: movil_atributos_mostrar
		});
	}

	getBackendMoviles (searchTerm) {
		return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

	getBackendPersonas (searchTerm) {
		return this.ajaxHandler.getJson('/personas/simple-search?filterNotInternal=true&search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

  getBackendTipoForms (searchTerm) {
		return this.ajaxHandler.getJson('/tipoformulario/simple-search?search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

	getBackendPersonasPorBase (searchTerm) {
		return this.ajaxHandler.getJson('/personas/simple-search?filterNotInternal=true&filterBase=true&search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

  getBackendTickets (searchTerm) {
		return this.ajaxHandler.getJson('/tickets/simple-search?number=10&search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

  ticketTareasModal(event){
    event.persist();

    if(this.state.formData.persona || this.state.formData.ticket){
      this.handleSubmit(event);
    } else {
      this.getTareas().then(() => {
        if (this.props.action === 'ADD' && (this.state.tareas != 0 || this.state.servicios != 0 || this.state.tareasEnResolucion || this.state.serviciosEnResolucion)) {
          $('#ticketTareasModal').modal('toggle');
        } else {
          this.handleSubmit(event);
        }
      });

      event.preventDefault();
    }
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
		this.state.formData.tipo_formulario_id = this.state.formData.tipo_formulario.id
		this.state.formData.movil_id = this.state.formData.movil ? this.state.formData.movil.id : null
		this.state.formData.persona_id = this.state.formData.persona ? this.state.formData.persona.id : null
    this.state.formData.ticket_id = this.state.formData.ticket ? this.state.formData.ticket.id : null
		this.state.formData.chofer_id = this.state.formData.chofer ? this.state.formData.chofer.id : null
		this.state.formData.valores_dinamicos = this.state.valores_dinamicos
		this.state.formData.latitudForm = this.state.latitudForm
		this.state.formData.longitudForm = this.state.longitudForm
		this.state.formData.tareas = this.state.tareas
    let atributoMedidor = this.state.valores_dinamicos.find(atributo => atributo.tipoValor == Constants.ID_MEDIDOR)
    if (this.state.formData.movil_id && atributoMedidor) {
      this.state.formData.medidor = atributoMedidor.value
    }

    this.ajaxHandler.fetch('/formulario' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    })
    .then(response => {
      if(response.status !== 400) {
        component.exit();
      } else {
        response.json().then(data => {
          this.setState({
            errors: data.detalle,
          });
        });
      }
      window.scrollTo(0, 0);
    })
    .catch((error) => {
      component.ajaxHandler.handleError(error);
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  event.preventDefault();
  }

  handleCancel(event) {
    this.exit();
  }

  exit() {
    this.setState({
      redirectTo: "/formulario",
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((event) => this.showPosition(event));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  getTareas(){
    this.setState({ loading: true });
    return new Promise ((resolve, reject) => {
      this.ajaxHandler.getJson('/tareas/movil/'+this.state.formData.movil.id)
      .then(data => {
         this.ajaxHandler.getJson('/tickets/gestoriaAbiertos/movil/'+this.state.formData.movil.id)
        .then(dataServicios => { 
          // Tareas del movil en curso (tickets activos)
          let tareasActivasEnTickets = data.map(tarea => tarea.id);
          let serviciosActivasEnTickets = dataServicios.map(ticket => ticket.servicio);
          let valoresDinamicos = JSON.parse(JSON.stringify(this.state.valores_dinamicos));
          let tareasDeFallas = [];
          let serviciosDeFallas = [];
          let tareasEnResolucion = false;
          let serviciosEnResolucion = false;

          // Chequeo estado de tareas que generan ticket
          valoresDinamicos.map(valor => {
            if(valor.estado == Constants.ID_ESTADO_APROBACION_INVALIDO) {
              //tareas fallidas
              if(valor.genera_ticket){
                //YA SE ACA SI ES CORRECTIVO
                let tareas = valor.tareas;
                tareas.map(tarea => {
                  // Filtro entre repedidas y en curso de resolucion
                  if (!tareasActivasEnTickets.includes(tarea.id)){
                    if (tareasDeFallas.filter(e => e.id === tarea.id).length === 0) {
                      tareasDeFallas.push(tarea)
                    }
                  } else {
                    // Tareas en curso de resolucion
                    tareasEnResolucion = true;
                  }
                })
              }else if(valor.genera_ticket_gestoria){
                let servicio = valor.servicio_gestoria;
                  if (!serviciosActivasEnTickets.includes(servicio.id)){
                    serviciosDeFallas.push(servicio);
                  }else{
                    serviciosEnResolucion = true;
                  }
              }
            }
        })
        this.setState({
          // Asigno tareas para el ticket
          tareas: tareasDeFallas,
          servicios: serviciosDeFallas,
          tareasEnResolucion: tareasEnResolucion,
          serviciosEnResolucion: serviciosEnResolucion,
  
        });
      })
      .finally(() => {
        resolve();
      this.setState({ loading: false });
      });
    })
    })
  }

  showPosition(position) {
    this.setState({
      latitudForm: position.coords.latitude,
      longitudForm: position.coords.longitude,
    });
  }

  getMovilAtributo(movilAtributoId){
    if(movilAtributoId === "modelo")
      return this.state.movil.modelo ? this.state.movil.modelo.nombre : '';
    else if (movilAtributoId === 'marca')
      return this.state.movil.modelo ? (this.state.movil.modelo.marca ? this.state.movil.modelo.marca.nombre : '') : '';
    else if (movilAtributoId === 'titular')
      return this.state.movil.titular_eloquent ? this.state.movil.titular_eloquent.nombre : this.state.movil.titular ? this.state.movil.titular.nombre : '';
    else if (movilAtributoId === 'plan_preventivo')
      return this.state.movil.plan_preventivo_eloquent ? this.state.movil.plan_preventivo_eloquent.descripcion : (this.state.movil.planPreventivo ? this.state.movil.planPreventivo.descripcion : '' );
    else if (movilAtributoId === 'centro_costos')
      return this.state.movil.cecos ? this.state.movil.cecos.nombre : (this.state.movil.centroCostos ? this.state.movil.centroCostos.nombre : '');
    else if (movilAtributoId === 'proveedor_gps')
      return this.state.movil.proveedor_gps_eloquent ? this.state.movil.proveedor_gps_eloquent.razon_social : (this.state.movil.proveedorGps ? this.state.movil.proveedorGps.razon_social: '');
    else
      return this.state.movil[movilAtributoId];
  }

  render() {
    let state = this.state;
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row content-body">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings["errors.default"])} />
                </li>
              ))}
            </div>
            <form className="form form-horizontal" ref="form" onSubmit={this.ticketTareasModal}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i>
                        <FormattedMessage
                          id="formularioAbm.general_data.label_datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                        <div className="float-right" style={{ fontSize: "14px" }}>
                          *{" "}
                          <FormattedMessage
                            id="formularioAbm.general_data.campos_requeridos"
                            defaultMessage="Campos requeridos"
                          />
                        </div>
                      </h4>

                      <div className="row">
                        {/* Tipo Formulario */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="identificador">
                              <FormattedMessage
                                id="formularioAbm.general_data.label_tipo_formulario"
                                defaultMessage="Tipo de Formulario *:"
                              />
                            </label>
                            <div className="col-md-8">
                              <Select.Async
                                name="tipo_formulario"
                                value={this.state.formData.tipo_formulario ? this.state.formData.tipo_formulario : null}
                                valueKey="id"
                                labelKey="nombre"
                                loadOptions={this.getBackendTipoForms}
                                onChange={this.handleTipoFormularioChange}
                                disabled={this.props.action === "VIEW"}
                              />
                            </div>
                            <label className="col-md-1">
                              <i
                                className={
                                  this.state.formData.tipo_formulario
                                    ? this.state.formData.tipo_formulario.icono + " fa-2x"
                                    : ""
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.formData.tipo_formulario && (
                        <div className="row">
                          {/* Descripción */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                                <FormattedMessage
                                  id="formularioAbm.general_data.label_descripcion"
                                  defaultMessage="Descripción:"
                                />
                              </label>
                              <div className="col-md-9">
                                <p className="col-form-label">{formData.tipo_formulario.descripcion} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {this.state.formData.usuario_validacion_id && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-thumbs-up "></i> Validación Manual
                        </h4>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="identificador">
                                Usuario:
                              </label>
                              <div className="col-md-9">
                                <p className="col-form-label">{this.state.formData.usuario_validacion.label}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="identificador">
                                Fecha:
                              </label>
                              <div className="col-md-9">
                                <p className="col-form-label">{this.state.formData.validated_at}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.formData.tipo_formulario && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage
                            id="formularioAbm.form_to_complete.label_formulario_completar"
                            defaultMessage=" Formulario a Completar "
                          />
                          <div className="float-right" style={{ fontSize: "14px" }}>
                            *{" "}
                            <FormattedMessage
                              id="formularioAbm.form_to_complete.campos requeridos"
                              defaultMessage="campos requeridos"
                            />
                          </div>
                        </h4>
                        {this.state.formData.tipo_formulario.con_persona ? (
                          <div className="row">
                            {/* Persona */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_persona"
                                    defaultMessage="Persona *:"
                                  />
                                </label>
                                <div className="col-md-9">
                                  <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.persona}
                                    valueKey="id"
                                    labelKey="label"
                                    loadOptions={this.getBackendPersonas}
                                    onChange={this.handlePersonaChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_movil ? (
                          <div className="row">
                            {/* Movil */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_movil"
                                    defaultMessage="Móvil *:"
                                  />
                                </label>
                                <div className="col-md-9">
                                  <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.movil}
                                    valueKey="id"
                                    labelKey="dominio"
                                    loadOptions={this.getBackendMoviles}
                                    onChange={this.handleMovilChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.movil && this.state.movil_atributos_mostrar.map((element, index) => {
                              return (
                                <div key={index} className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                                      {element.nombre} :
                                    </label>
                                    <div className="col-md-9">
                                      <div className="form-control-static col-form-label form-value">
                                        {this.getMovilAtributo(element.id)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_ticket ? (
                          <div className="row">
                            {/* TICKET */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="Ticket"
                                    defaultMessage="Ticket"
                                  /> *:
                                </label>
                                <div className="col-md-9">
                                  <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.ticket}
                                    valueKey="id"
                                    labelKey="id"
                                    loadOptions={this.getBackendTickets}
                                    onChange={this.handleTicketChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_ticket ? (
                          <div className="row">
                            {/* VEHICULO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="Vehiculo"
                                    defaultMessage="Vehículo"
                                  /> :
                                </label>
                                <div className="col-md-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.state.formData.ticket ? this.state.formData.ticket.dominio : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_chofer ? (
                          <div className="row">
                            {/* Chofer */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label">Actualizar Chofer *:</label>
                                <div className="col-md-9">
                                  <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.chofer}
                                    valueKey="id"
                                    labelKey="label"
                                    loadOptions={this.getBackendPersonasPorBase}
                                    onChange={this.handleChoferChange}
                                    disabled={this.props.action === "VIEW" || !this.state.isChoferValid}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.valores_dinamicos.map(
                          (atributoDinamico, index) =>
                            atributoDinamico &&
                            atributoDinamico.activo && (
                              <div className="row" key={index}>
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <AtributoDinamicoInstancia
                                      movil={formData.movil}
                                      ocultarInput={this.props.action === "VIEW"}
                                      disabled={this.props.action === "VIEW" || this.state.entidad == null}
                                      handleChange={(name, value) =>
                                        this.handleChangeAtributoDinamico(index, name, value)
                                      }
                                      atributo={atributoDinamico}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== "VIEW" && (
                          <>
                            <button
                              type="submit"
                              className="btn btn-primary mr-1"
                              disabled={
                                !validationState.form.valid ||
                                atributosDinamicosRequeridosFaltantes(this.state.valores_dinamicos)
                              }
                            >
                              <i className="fa fa-check-circle"></i>
                              <FormattedMessage id="formularioAbm.finish_button.guardar" defaultMessage=" Guardar" />
                            </button>
                          </>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i>{" "}
                          {this.props.action === "VIEW"
                            ? this.props.intl.formatMessage({
                                id: "formularioAbm.finish_button.volver",
                                defaultMessage: "Volver",
                              })
                            : this.props.intl.formatMessage({
                                id: "formularioAbm.finish_button.cancelar",
                                defaultMessage: "Cancelar",
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal fade" tabIndex="-1" id="ticketTareasModal" role="dialog" aria-labelledby="ticketTareasModalLable">
          <div className="modal-lg modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="ticketTareasModalTitle">
                  <FormattedMessage id="FormularioAbm.ticket_modal.title" defaultMessage="Ticket"/>
                </h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <div className="container-fluid">
                  {(this.state.tareas != 0) ? (
                    <div>
                      {<FormularioTicketTareas tareas={this.state.tareas}></FormularioTicketTareas>}
                    </div>
                  ) : ''}
                  {(this.state.servicios != 0 && ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') ? (
                    <div>
                      <div className="card pull-up form-body">
                        <div className="card-content">
                          <div className="card-body">
                            <h4 className="form-section">
                              <FormattedMessage id="FormularioTicketTareas.render.modal.header_gestoria" defaultMessage="Se van a generar tickets de gestoría con los siguientes servicios"/>
                            </h4>
                            <div className="row">
                              <div className="col-md-12">
                                <table className="table">
                                  <thead className="thead-fleet">
                                    <tr>
                                      <th scope="col"><FormattedMessage id="FormularioTicketTareas.render.modal.table_column_servicios" defaultMessage="Servicios"/></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {this.state.servicios.map((servicio, index)=>{
                                    return(
                                      <tr key={index}>
                                        <td className="align-middle">{servicio.nombre}</td>
                                      </tr>
                                    );
                                  })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ''}
                  {this.state.tareasEnResolucion == true || this.state.serviciosEnResolucion == true ?
                    <div className="form-horizontal" id="tareasFallidasEnCurso">
                      <div className="card-content card">
                          <div className="card-body">
                          <div className="text-cd text-center">
                            <FormattedMessage id="FormularioAbm.ticket_tareas_modal.task_not_included" defaultMessage="Hay fallas encontradas que no generan tickets o ya tienen un ticket creado"/>
                          </div>
                          </div>
                      </div>
                    </div>
                  : ''}
                </div>

              </div>
              <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-primary mr-1" data-dismiss="modal" onClick={this.handleSubmit} ><FormattedMessage id="FormularioAbm.ticket_tareas_modal.button_ok" defaultMessage="Ok"/></button>
                  <button type="button" className="btn btn-danger" data-dismiss="modal" ><FormattedMessage id="FormularioAbm.ticket_tareas_modal.button_cancel" defaultMessage="Cancel"/></button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(FormularioAbm);
