import React, { useState, useEffect} from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
	Collapse,
	TextField,
	CircularProgress,
	Grid,
	Box,
} from '@material-ui/core';
import Switch from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

const FILTER_STATE_INIT = {
	marca: null,
	estado: [],
	tipo: null,
	pais: null,
	region: null,
	subRegion: '',
	base: null,
	titular: null,
	companiaOrigen: null,
	centroCosto: null,
	unidad:'',
	chasis: '',
	dominio: '',
	modelo: '',
	cecosDireccion: '',
	cecosMercado: '',
	cecosGerencia: '',
	cecosArea: '',
	responsable1: '',
	medidor: '',
	medidorDesde: '',
	medidorHasta: '',
	showInactivos: false,
	filtroKpi: ''
}

const QUERY_INIT = {
	marca: '',
	estado: [],
	tipo: '',
	pais: '',
	region: '',
	subRegion: '',
	base: '',
	titular: '',
	companiaOrigen: '',
	centroCosto: '',
	unidad:'',
	chasis: '',
	dominio: '',
	modelo: '',
	cecosDireccion: '',
	cecosMercado: '',
	cecosGerencia: '',
	cecosArea: '',
	responsable1: '',
	medidor: '',
	medidorDesde: '',
	medidorHasta: '',
	showInactivos: false,
	filtroKpi: ''
}

const handleInitFilters = () => {
	let filterInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT))
	let storage = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))
	if(storage && storage.activo && storage.filtros){
		filterInit = storage.filtros
	}
	return filterInit
}

const MovilesGridFiltros = (props) => {
	const intl = useIntl();
	const columnsEnabled = props.columnsEnabled
	const numberInputEexceptSymbols = ["e", "E", "+", "-", ".",  ","]

	const [init,setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(handleInitFilters)

	//Filters Selects values
	const [marcas, setMarcas] = useState([])
	const [estados, setEstados] = useState([])
	const [tipos, setTipos] = useState([])
	const [paises, setPaises] = useState([])
	const [regiones, setRegiones] = useState([])
	const [subRegiones, setSubRegiones] = useState([])
	const [bases, setBases] = useState([])
	const [titulares, setTitulares] = useState([])
	const [companiasOrigen, setCompaniasOrigen] = useState([])
	const [centrosCostos, setCentrosCostos] = useState([])
	const [validatorSearch, setValidatorSearch] = useState(false)

	useEffect(() => {
		fetchData()
		initFilter()
	}, []);

	useEffect(() => {
		if(props.filterResetInputs){
			setFiltersState(JSON.parse(JSON.stringify(FILTER_STATE_INIT)));
			window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: false, filtros: FILTER_STATE_INIT}));
		}
	},[props.filterResetInputs])

	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	useEffect(() => {
		if(props.estadosFiltro.estados){
			let estados = props.estadosFiltro.estados ? props.estadosFiltro.estados.split(',') : []
			let estadoSelect =  [];
			estados.forEach(estado => {
				estadoSelect.push({ value: estado, label: estado });
			});
			filtersState['estado'] = estadoSelect;
			let copyFiltersState = JSON.parse(JSON.stringify(filtersState));

			let currentFilters = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))['filtros'];
			if( JSON.stringify(currentFilters['estado']) !== JSON.stringify(estadoSelect)){
				window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: props.persisteFiltros, filtros: copyFiltersState}));
			}

			setFiltersState(copyFiltersState);
			props.handleFilterChange();
			props.setSearchActive(true);
			props.setDropdownActive(true);

			let query = queryTable();
			props.setFilterQuery(query);
		}
	}, [props.estadosFiltro]);

	const fetchData = () => {
		setFiltersLoading(true)
		Promise.all([
			Utils.getData('/marcas/select'),
			ConfigBusiness.get('moviles.estados'),
			Utils.getData('/modelo-tipos/select'),
			Utils.getData('/regiones/select'),
			Utils.getData('/bases/select-filtered-by-user/movil'),
			Utils.getData('/movil-titulares/select'),
			ConfigBusiness.get('moviles.companiaOrigen.valores'),
			Utils.getData('/centros-costos/select'),
			Utils.getData('/regiones/select'),
			Utils.getData('/subregiones/select-all'),
			Utils.getData('/paises/select'),
		]).then((data) => {
			setMarcas(data[0] ? data[0] : [])
			let estados = data[1] ? data[1].split(',') : null;
			let menuEstado = [];
			if(estados != null){
				estados.forEach(estado => {
					menuEstado.push({ value: estado, label: estado });
				});
			}
			setEstados(menuEstado);
			setTipos(data[2] ? data[2] : [])

			setPaises(data[10])
			
			setRegiones(data[3] ? data[3] : [])
			setBases(data[4] ? data[4] : [])
			setTitulares(data[5] ? data[5] : [])
			let companiasOrigen = data[6] ? data[6].split(',') : null;
			let menuCompaniasOrigen = [];
			if(companiasOrigen != null){
				companiasOrigen.forEach(compania => {
					menuCompaniasOrigen.push({ value: compania, label: compania });
				});
			}
			setCompaniasOrigen(menuCompaniasOrigen)
			setCentrosCostos(data[7] ? data[7] : [])
			setRegiones(data[8] ? data[8] : [])
			setSubRegiones(data[9] ? data[9] : [])
		}).catch((err) => {
			setFiltersLoading(false)
			console.log(err);
		})
	}

	const initFilter = () => {
		setFiltersLoading(true);
		let query = {}

		if(props.persisteFiltros || validatorNotNull()){
			props.setSearchActive(true);
		}
		
		query = queryTable();
		
		if(props.kpi){
			props.handleAlertClick(props.kpi.filter)
		}
		setInit(true)
		
		window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: props.persisteFiltros, filtros: filtersState}));
		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	}

	const queryTable = () => {
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));
		if(filtersState.estado && filtersState.estado.length){
			query['estado'] = filtersState.estado.map((estado)=>estado.value).join(',')
		}
		query['marca'] = filtersState.marca ? filtersState.marca.value : ''
		query['tipo'] = filtersState.tipo ? filtersState.tipo.value : ''
		query['pais'] = filtersState.pais ? filtersState.pais.value : ''
		query['region'] = filtersState.region ? filtersState.region.value : ''
		query['subRegion'] = filtersState.subRegion ? filtersState.subRegion.value : ''
		query['base'] = filtersState.base ? filtersState.base.value : ''
		query['titular'] = filtersState.titular ? filtersState.titular.value : ''
		query['companiaOrigen'] = filtersState.companiaOrigen ? filtersState.companiaOrigen.value : ''
		query['centroCosto'] = filtersState.centroCosto ? filtersState.centroCosto.value : ''

		query['unidad'] = filtersState.unidad ? filtersState.unidad : ''
		query['chasis'] = filtersState.chasis ? filtersState.chasis : ''
		query['dominio'] = filtersState.dominio ? filtersState.dominio : ''
		query['modelo'] = filtersState.modelo ? filtersState.modelo : ''
		query['cecosDireccion'] = filtersState.cecosDireccion ? filtersState.cecosDireccion : ''
		query['cecosMercado'] = filtersState.cecosMercado ? filtersState.cecosMercado : ''
		query['cecosGerencia'] = filtersState.cecosGerencia ? filtersState.cecosGerencia : ''
		query['cecosArea'] = filtersState.cecosArea ? filtersState.cecosArea : ''
		query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
		query['medidor'] = filtersState.medidor ? filtersState.medidor : ''
		query['showInactivos'] = filtersState.showInactivos ? filtersState.showInactivos : ''
		query['medidorDesde'] = filtersState.medidorDesde ? filtersState.medidorDesde : ''
		query['medidorHasta'] = filtersState.medidorHasta ? filtersState.medidorHasta : ''
		if(props.kpi){
			query['filtroKpi'] = props.kpi.filter
		}
		return query;
	}

	const handleChange = (name, object) => {
		let currentFilters = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))['filtros'];
		if( JSON.stringify(currentFilters[name]) !== JSON.stringify(object)){
			filtersState[name] = object;
			let copyFiltersState = JSON.parse(JSON.stringify(filtersState));
			setFiltersState(copyFiltersState);
			window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: props.persisteFiltros, filtros: copyFiltersState}));
			return;
		}
	};

	const handleChangeInactivosSwich = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
	};

	useEffect(() => {
		if(init){
			if(props.persisteFiltros){
				window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: true, filtros: filtersState}));
			}
			else{
				setFiltersState(JSON.parse(JSON.stringify(FILTER_STATE_INIT)));
				props.setFilterQuery(JSON.parse(JSON.stringify(QUERY_INIT)));
				window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: false, filtros: FILTER_STATE_INIT}));
			} 
		}
	},[props.persisteFiltros])

	useEffect(() => {
		setValidatorSearch(validator());
	},[filtersState])

	const validator = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(Array.isArray(result[index][1])){
				if(result[index][1].length > 0 ){
					return false
				}
			}else{
				if(result[index][1]){
					return false
				}
			}
		}
		return true
	}

	const validatorNotNull = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(Array.isArray(result[index][1])){
				if(result[index][1].length > 0){
					return true
				}
			}else{
				if(result[index][1]){
					return true
				}
			}
		}
		return false
	}

	const handleSubmit = (event) => {
		props.handleFilterChange();
		event.preventDefault();
		props.setSearchActive(true)
		let query = queryTable();
		props.setFilterQuery(query);
		return;
	}

	const onChangePersistentFilter = (value) => {
		window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: value, filtros: filtersState}));
		props.setPersisteFiltros(value)
	}

	const handleSubmitInto = (event) => {
		if(!props.loading && !validatorSearch){
			handleSubmit(event);
		}
		event.preventDefault();
	}
	return (
		<>
		{init &&
			<Collapse in={props.dropdownActive}>
				<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="marca"
								disabled={filtersLoading}
								options={marcas}
								value={filtersState.marca}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, v) => handleChange('marca', v)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.label", defaultMessage: "Marca" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.placeholder", defaultMessage: "Marca" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								multiple={true}
								size="small"
								name="estado"
								disabled={filtersLoading}
								options={estados}
								value={filtersState.estado}
								defaultValue={[]}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, v) => handleChange('estado', v)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "movilesGrid.render.filtros_estados.label",
											defaultMessage: "Estados"
										})}
										placeholder={intl.formatMessage({
											id: "movilesGrid.render.filtros_estados.placeholder",
											defaultMessage: "Estados"
										})}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.unidadEnabled && {display:"none"}}  component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.label", defaultMessage: "Unidad" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.placeholder", defaultMessage: "Unidad" })}
								name="unidad"
								value={filtersState.unidad}
								onChange={(e) => handleChange('unidad', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.chasisEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.label", defaultMessage: "Chasis" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.placeholder", defaultMessage: "Chasis" })}
								name="chasis"
								value={filtersState.chasis}
								onChange={(e) => handleChange('chasis', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.label", defaultMessage: "Dominio" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.placeholder", defaultMessage: "Dominio" })}
								name="dominio"
								value={filtersState.dominio}
								onChange={(e) => handleChange('dominio', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.label", defaultMessage: "Modelo" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.placeholder", defaultMessage: "Modelo" })}
								name="modelo"
								value={filtersState.modelo}
								onChange={(e) => handleChange('modelo', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="tipo"
								disabled={filtersLoading}
								options={tipos}
								value={filtersState.tipo}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('tipo', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.label", defaultMessage: "Tipo" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.placeholder", defaultMessage: "Tipo" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.paisEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="pais"
								disabled={filtersLoading}
								options={paises}
								value={filtersState.pais}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('pais', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.label", defaultMessage: "País" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.placeholder", defaultMessage: "País" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.regionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="region"
								disabled={filtersLoading}
								options={regiones}
								value={filtersState.region}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('region', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_region.label", defaultMessage: "Región" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_region.placeholder", defaultMessage: "Región" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.subRegionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="subRegion"
								disabled={filtersLoading}
								options={subRegiones}
								value={filtersState.subRegion}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('subRegion', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.label", defaultMessage: "Sub.Región" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.placeholder", defaultMessage: "Sub.Región" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.baseEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="base"
								disabled={filtersLoading}
								options={bases}
								value={filtersState.base}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('base', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_base.label", defaultMessage: "Base" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_base.placeholder", defaultMessage: "Base" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.titularEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="titular"
								disabled={filtersLoading}
								options={titulares}
								value={filtersState.titular}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('titular', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.label", defaultMessage: "Titular" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.placeholder", defaultMessage: "Titular" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.companiaOrigenEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="companiaOrigen"
								disabled={filtersLoading}
								options={companiasOrigen}
								value={filtersState.companiaOrigen}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('companiaOrigen', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.label", defaultMessage: "Cía. Origen" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.placeholder", defaultMessage: "Cía. Origen" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.centroCostosEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="centroCosto"
								disabled={filtersLoading}
								options={centrosCostos}
								value={filtersState.centroCosto}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('centroCosto', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.label", defaultMessage: "Centro de Costos" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.placeholder", defaultMessage: "Centro de Costos" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{filtersLoading ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!columnsEnabled.cecosDireccionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.label", defaultMessage: "CC.Dirección" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.placeholder", defaultMessage: "CC.Dirección" })}
								name="cecosDireccion"
								value={filtersState.cecosDireccion}
								onChange={(e) => handleChange('cecosDireccion', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosMercadoEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.label", defaultMessage: "CC.Mercado" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.placeholder", defaultMessage: "CC.Mercado" })}
								name="cecosMercado"
								value={filtersState.cecosMercado}
								onChange={(e) => handleChange('cecosMercado', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosGerenciaEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.label", defaultMessage: "CC.Gerencia" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.placeholder", defaultMessage: "CC.Gerencia" })}
								name="cecosGerencia"
								value={filtersState.cecosGerencia}
								onChange={(e) => handleChange('cecosGerencia', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosAreaEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.label", defaultMessage: "CC.Área" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.placeholder", defaultMessage: "CC.Área" })}
								name="cecosArea"
								value={filtersState.cecosArea}
								onChange={(e) => handleChange('cecosArea', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid {...!columnsEnabled.responsable1Enabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.label", defaultMessage: "Responsable 1" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.placeholder", defaultMessage: "Responsable 1" })}
								name="responsable1"
								value={filtersState.responsable1}
								onChange={(e) => handleChange('responsable1', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<Grid container item xs={12} spacing={3}>
							<Grid component={Box} item xs={6}>
								<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
									<TextField
										label={intl.formatMessage({ id: "Medidor_desde", defaultMessage: "Medidor desde" })}
										name="medidorDesde"
										type="number"
										value={filtersState.medidorDesde}
										onChange={(e) => handleChange('medidorDesde', e.target.value)}
										disabled={filtersLoading}
										className="col-12"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</form>
								{ filtersState.medidorHasta !== '' && 
									filtersState.medidorDesde !== filtersState.medidorHasta &&
									Math.min(filtersState.medidorDesde, filtersState.medidorHasta) == filtersState.medidorHasta && (
								<small className="text-danger">< FormattedMessage id="movilesGrid.filters.error_valores_medidor" defaultMessage="Valores incorrectos" /></small>
								)}
							</Grid>
							<Grid component={Box} item xs={6}>
								<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
									<TextField
										label={intl.formatMessage({ id: "Medidor_hasta", defaultMessage: "Medidor hasta" })}
										name="medidorHasta"
										type="number"
										value={filtersState.medidorHasta}
										onChange={(e) => handleChange('medidorHasta', e.target.value)}
										disabled={filtersLoading}
										className="col-12"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</form>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						xs={12}
						container
						justifyContent="space-between"
						alignItems="center"
						flexDirection={{ xs: 'column', sm: 'row' }}
						sx={{ fontSize: '12px' }}
					>
						<Grid component={Box} item xs={2} className="align-self-end">
							<div className="d-flex inline justify-content-around align-items-center">
								<FormattedMessage
									id="formulariosGridFilters.render.filtros.ver_inactivos"
									defaultMessage="Ver Inactivos"
								/>
								<Switch
									onChange={(value) => handleChangeInactivosSwich(value)}
									checked={filtersState.showInactivos}
									disabled={filtersLoading}
									offColor="#FF4961"
									onColor="#28D094"
								/>
							</div>
						</Grid>
						<Grid component={Box} item xs={2} className="align-self-end">
							<div className="d-flex inline justify-content-around align-items-center">
								<FormattedMessage
									id="movilesGrid.render.filtros.persistir_filtros"
									defaultMessage="Persistir Filtros"
								/>
								<Switch
									onChange={(value) => onChangePersistentFilter(value)}
									checked={props.persisteFiltros}
									disabled={filtersLoading}
									offColor="#FF4961"
									onColor="#28D094"
								/>
							</div>
						</Grid>
						<Grid component={Box} item xs={1} className="align-self-end"
						>
							<button
								disabled={props.loading || validatorSearch}
								className="btn btn-primary mr-1"
								onClick={(e) => handleSubmit(e)}
							>
								<i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar"/>
							</button>
						</Grid>
					</Grid>
				</Grid>
			</Collapse>
	}
		</>
	)
}

export default MovilesGridFiltros
