import { TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import iconoCheklist from '../../../src/assets/images/iconos/icono-checklist.png';
import Timezone from '../../commons/timezone/Timezone.js';
import {
  StyledTableCellProps as StyledTableCell,
  StyledTooltip,
} from '../../commons/utils/TableStyles';

const TicketsGridTableRow = (props) => {
  const intl = useIntl();
  const ticket = props.ticket;
  const columnsEnabled = props.columnsEnabled;
  const [redirectTo, setRedirectTo] = useState(null);

  const goToTicket = (e) => {
    e.preventDefault();
    setRedirectTo(props.ticketUrl);
  };

  const TicketGridCell = ({
    show = true,
    className,
    onClick = goToTicket,
    align = 'left',
    style,
    children,
  }) => {
    return (
      <StyledTableCell
        show={show}
        className={className}
        onClick={onClick}
        align={align}
        style={style}
      >
        {children}
      </StyledTableCell>
    );
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}

      <TableRow className="all cursor-pointer action edit" style={{ backgroundColor: '#d3d3d34f' }}>
        <StyledTableCell show={props.generacionMasiva} style={{ maxWidth: '30px' }}>
          <input
            type="checkbox"
            checked={props.selectedTickets.includes(ticket.ticket_id)}
            onChange={props.handleChecked(ticket.ticket_id)}
            style={{ maxWidth: '22px' }}
            className="customCheckbox cursor-pointer"
            id={ticket.ticket_id}
          />
        </StyledTableCell>

        <TicketGridCell>
          <div className="d-flex">
            <StyledTooltip title={props.ticketTipo} arrow placement="top">
              <div className={getAvatarTipo(ticket.tipo) + ' avatar dt-user-avatar'}>
                {props.ticketTipo ? props.ticketTipo.substr(0, 1) : ''}
              </div>
            </StyledTooltip>
          </div>
        </TicketGridCell>

        <TicketGridCell
          onClick={() => {
            setRedirectTo(`formulario/${ticket.formulario}`);
          }}
        >
          {ticket.origen === 'Checklist' && ticket.formulario_id ? (
            <StyledTooltip title={ticket.formulario_id} arrow placement="top">
              <div
                className=""
                style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
              >
                <img
                  src={iconoCheklist}
                  alt={ticket.origen}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />
                <span>{ticket.ticket_id}</span>
              </div>
            </StyledTooltip>
          ) : (
            <div>
              <span style={{ marginLeft: '10px' }}>{ticket.ticket_id}</span>
            </div>
          )}
        </TicketGridCell>

        <TicketGridCell
          className={
            ticket.estado_color === 4
              ? 'status-yellow'
              : ticket.estado_color === 2
                ? 'status-red'
                : ticket.estado_color === 1
                  ? 'status-green'
                  : 'status-grey'
          }
        ></TicketGridCell>

        <TicketGridCell show={columnsEnabled.estado}>{props.ticketEstado}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.nivelAprobacionPresupuesto}>
          {ticket.nivel_aprobacion}
        </TicketGridCell>

        <TicketGridCell>
          {ticket.fecha_hora_alta
            ? Timezone.getDateForClient(ticket.fecha_hora_alta, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
            : ''}
        </TicketGridCell>

        <TicketGridCell>
          <StyledTooltip title={ticket.detalle || ''} arrow placement="top">
            <div className="text-truncate" style={{ maxWidth: '150px' }}>
              {ticket.detalle}
            </div>
          </StyledTooltip>
        </TicketGridCell>

        <TicketGridCell>{ticket.servicio}</TicketGridCell>

        <TicketGridCell>
          <button
            className="px-0 btn btn-sm btn-ic n btn-dt-grid text-dark"
            title={intl.formatMessage({
              id: 'ticketsGrid.column_actions.button_title_trabajar_con_ticket',
              defaultMessage: 'Trabajar con ticket',
            })}
            data-togle="tooltip"
            data-placement="top"
          >
            <GetEntidadIcon ticket={ticket} />
            {ticket.entidad}
          </button>
        </TicketGridCell>

        <TicketGridCell>{ticket.gerenciador}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.region}>{ticket.region}</TicketGridCell>

        <TicketGridCell>{ticket.base}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.pais}>{ticket.pais}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.centroCostos}>{ticket.centro_costos}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.centroBeneficios}>
          {ticket.centro_beneficios}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.responsable1}> {ticket.responsable1}</TicketGridCell>

        <TicketGridCell>
          <EnTaller enTaller={ticket.en_taller} />
        </TicketGridCell>

        <TicketGridCell>{ticket.mtt_total}</TicketGridCell>

        <TicketGridCell>{ticket.mtt_parcial}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.encuestaSatisfaccion}>
          {ticket.encuesta_satisfaccion_nivel}
        </TicketGridCell>

        <TicketGridCell>
          {ticket.fecha_hora_realizado
            ? Timezone.getDateForClient(ticket.fecha_hora_realizado, 'YYYY-MM-DD HH:mm:ss', 'L')
            : ''}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.fechaHoraTurno}>
          {ticket.fecha_hora_turno
            ? Timezone.getDateForClient(ticket.fecha_hora_turno, 'YYYY-MM-DD HH:mm:ss', 'L')
            : ''}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.abono}>
          {ticket.abono === "1"
            ? intl.formatMessage({ id: 'ticketsGrid.column.abono,si', defaultMessage: 'Si' })
            : intl.formatMessage({ id: 'ticketsGrid.column.abono.no', defaultMessage: 'No' })}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.tipoModelo}>{ticket.modelo_tipo}</TicketGridCell>

        <TicketGridCell>
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
            title={intl.formatMessage({
              id: 'ticketsGrid.column_actions.button_title_trabajar_con_ticket',
              defaultMessage: 'Trabajar con ticket',
            })}
            data-togle="tooltip"
            data-placement="top"
          >
            <i className="fa fa-pencil fa-xs"></i>
          </button>
        </TicketGridCell>
      </TableRow>
    </>
  );
};

const getAvatarTipo = (tipo) => {
  switch (tipo) {
    case "PREVENTIVO":
      return "paleta-preventivo";
    case "VENCIMIENTO":
      return "paleta-vencimiento";
    case "GESTORIA":
      return "paleta-gestoria";
    case "CORRECTIVO":
      return "paleta-correctivo";
    default:
      break;
  }
};


const EnTaller = ({ enTaller }) => {
  const intl = useIntl();

  if (enTaller === "1") {
    return (
      <div className="text-center default dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_default.label_taller_si_sin_verificacion',
            defaultMessage: 'SI - Sin Verificación',
          })}
        ></i>
      </div>
    );
  } else if (enTaller === "2") {
    return (
      <div className="text-center success dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_success.label_taller_si_verificado_gps',
            defaultMessage: 'SI - Verificado GPS',
          })}
        ></i>
      </div>
    );
  } else if (enTaller === "3") {
    return (
      <div className="text-center danger dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_danger.label_taller_no_verificado_gps',
            defaultMessage: 'No - Verificado GPS',
          })}
        ></i>
      </div>
    );
  } else {
    return '';
  }
};

const GetEntidadIcon = ({ ticket }) => {
  if (ticket.llanta_id !== null) {
    return (
      <img
        src="/images/llantas/tire.svg"
        style={{ height: '16px', width: '16px', marginRight: '4px', marginBlockEnd: '4px' }}
      ></img>
    );
  }

  if (ticket.movil_id !== null) {
    return <i className="la la-car" style={{ marginRight: '3px' }}></i>;
  }

  if (ticket.persona_id !== null) {
    return <i className="la la-user" style={{ marginRight: '3px' }}></i>;
  } else {
    return <i className="la la-dog" style={{ marginRight: '3px' }}></i>;
  }
};

export default TicketsGridTableRow;
